import './App.css';
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from './Components/Nav';
import Header from './Components/Header';
import Home from './Pages/Home';
import Footer from './Components/Footer';
function App() {
return (
<>
  <Router>
    <Nav />
    <Header />
    <Routes>
      <Route path='/' index element={<Home/>}></Route>
    </Routes>
    <Footer/>
  </Router>
</>
);
}

export default App;