import React from "react";
import image_01 from "../Assets/images/products/01.jpg";
import image_02 from "../Assets/images/products/02.jpg";
import image_03 from "../Assets/images/products/03.jpg";
import image_04 from "../Assets/images/products/04.jpg";
const RecommendedProducts = () => {
    return (
        <>
            <div className="container-fluid block-recommended">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <h2>Recommended to you</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_01} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small class="text-muted"><strong>R900</strong></small></p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_02} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small class="text-muted"><strong>R1900</strong></small></p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_03} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small class="text-muted"><strong>R1900</strong></small></p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_04} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text"><small class="text-muted"><strong>R2900</strong></small></p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecommendedProducts;