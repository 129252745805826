import React from "react";
import { Link } from "react-router-dom";
import person from "../Assets/images/products/person.jpg";
import 'animate.css';

const Nav = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <nav className="navbar navbar-expand-lg bg-light block-nav">
              <Link to="/products" className="navbar-brand">Creative Store</Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active" aria-current="page">Home</Link>
                  </li>
                  <li className="nav-item dropdown ">
                    <Link to="/products" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Products
                    </Link>
                    <ul className="dropdown-menu animate__animated animate__backInLeft">
                      <li>
                        <Link to="/products" className="dropdown-item">Most popular</Link>
                      </li>
                      <li>
                        <Link to="/products" className="dropdown-item">Recommended</Link>
                      </li>
                      <li>
                        <Link to="/products" className="dropdown-item">For Sale</Link>
                      </li>
                      <li>
                        <Link to="/products" className="dropdown-item">Available</Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link to="/products" className="dropdown-item">Something else here</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link to="/products" className="nav-link">Browse</Link>
                  </li>
                </ul>
                <div className="dropdown text-end">
                  <Link to="/products" className="d-block link-dark text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={person} alt="Person" className="rounded-circle" width="32" height="32" />
                  </Link>
                  <ul className="dropdown-menu text-small animate__animated animate__backInLeft">
                    <li>
                      <Link to="/products" className="dropdown-item">Account</Link>
                    </li>
                    <li>
                      <Link to="/products" className="dropdown-item">Settings</Link>
                    </li>
                    <li>
                      <Link to="/products" className="dropdown-item">Profile</Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link to="/products" className="dropdown-item">Sign out</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
export default Nav;