import React from "react";
import image from "../Assets/images/header.png"
const Header = () => {
return (
<>
    <div className="container-fluid block-header">
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <div className="row">
                    <div className="col-lg-6 block-text-header">
                        <h1>Welcome to Creative Store</h1>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a
                            page when looking at its layout. It is a long established fact that a reader will be
                            distracted by the readable content of a page when looking at its layout. lorem ipsum' will uncover many web sites still in their infancy. </p>
                    </div>
                    <div className="col-lg-6">
                    <img src={image} class="img-fluid block-header-img" alt="Buy online"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)
}

export default Header;