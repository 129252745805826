import React from "react";

const Footer = () => {
return (
<>
    <div className="container-fluid block-recommended">
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <div className="row">
                    <footer
                        className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                        <div className="col-md-4 d-flex align-items-center">
                            <span className="mb-3 mb-md-0 text-muted">© 2022 Company, Inc</span>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</>
)
}

export default Footer;