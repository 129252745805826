import React from "react";
import image_05 from "../Assets/images/products/05.jpg";
import image_06 from "../Assets/images/products/06.jpg";
import image_07 from "../Assets/images/products/07.jpg";
import image_08 from "../Assets/images/products/08.jpg";

const Trending = () => {
    return (
        <>
            <div className="container-fluid block-recommended">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <h2>Trending</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_05} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_06} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_07} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div class="card shadow">
                                    <img src={image_08} class="card-img-top" alt="Online Products" />
                                    <div className="card-body">
                                        <h5 className="card-title">Online Products</h5>
                                        <p className="card-text">This is a wider card with supporting text below as a natural
                                            lead-in to additional content. This content is a little bit longer.</p>
                                        <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trending;