import React from "react";
import image_09 from "../Assets/images/products/09.jpg";
import image_10 from "../Assets/images/products/10.jpg";
import image_03 from "../Assets/images/products/03.jpg";
import image_11 from "../Assets/images/products/11.jpg";
const Popular = () => {
return (
<>
    <div className="container-fluid block-recommended">
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <h2>Most Popular</h2>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-10">
                <div className="row">
                    <div className="col-lg-3">
                        <div class="card shadow">
                            <img src={image_09} class="card-img-top" alt="Online Products" />
                            <div className="card-body">
                                <h5 className="card-title">Online Products</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="card shadow">
                            <img src={image_10} class="card-img-top" alt="Online Products" />
                            <div className="card-body">
                                <h5 className="card-title">Online Products</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="card shadow">
                            <img src={image_03} class="card-img-top" alt="Online Products" />
                            <div className="card-body">
                                <h5 className="card-title">Online Products</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div class="card shadow">
                            <img src={image_11} class="card-img-top" alt="Online Products" />
                            <div className="card-body">
                                <h5 className="card-title">Online Products</h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural
                                    lead-in to additional content. This content is a little bit longer.</p>
                                <p className="card-text"><small class="text-muted">Posted 3 mins ago</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)
}

export default Popular;