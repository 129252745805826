import React from "react";
import Popular from "../Products/Popular";
import RecommendedProducts from "../Products/Recommended-Products";
import Trending from "../Products/Trending";

const Home = () => {

    return (
        <>
            <RecommendedProducts />
            <Trending />
            <Popular />
        </>
    )
}

export default Home;